import React from 'react';

import TopImage from '../component/top-image';
import SelfTop from '../component/self-top'

import '../assets/css/page-four.css';

class PageFour extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="page-four">
                <TopImage />
                <div className="content" onClick={(e) => { this.message(e) }}>
                    <SelfTop />
                    <div className="space"></div>
                    <div className="self-info">
                        <div className="self-item">
                            <div className="self-icon phone"></div>
                            <div className="self-txt">18468053510</div>
                        </div>
                        <div className="self-item">
                            <div className="self-icon mobile"></div>
                            <div className="self-txt"><a href="tel:18468053510">18468053510</a></div>
                        </div>
                        <div className="self-item">
                            <div className="self-icon email"></div>
                            <div className="self-txt">577279599@qq.com</div>
                        </div>
                        <div className="self-item">
                            <div className="self-icon language"></div>
                            <div className="self-txt">汉语</div>
                        </div>
                        <div className="self-item">
                            <div className="self-icon address"></div>
                            <div className="self-txt">云南省昆明市盘龙区欣都龙城A2栋802室</div>
                        </div>
                        <div className="self-item">
                            <div className="self-icon post"></div>
                            <div className="self-txt">交通事故、债权债务、婚姻家庭、劳动纠纷、刑事辩、法律顾问</div>
                        </div>
                    </div>
                </div>
                <div className="perch"></div>
                {/* <a className="submit-btn" href="wtai:18468053510">保存到通讯录</a> */}
                {/* <a className="submit-btn" href="tel://18468053510">保存到通讯录</a> */}
            </div>
        );
    }

    message = (e) => {
        this.props.history.push({
            pathname: `/message`
        });
    }

}

export default PageFour;