import '../assets/css/loadding.css'

function Loadding() {
    return (
        <div className="loadding">
            <div className="icon"></div>
        </div>
    )
}

export default Loadding;