function Business() {
    return (
        <div className="domain">
            <div className="title">
                <div className="title-line"></div>
                <div className="title-txt">业务领域</div>
            </div>
            <div className="business">
                <div className="businessItem">交通事故</div>
                <div className="businessItem">债权债务</div>
                <div className="businessItem">婚姻家庭</div>
                <div className="businessItem">劳动纠纷</div>
                <div className="businessItem">刑事辩护</div>
                <div className="businessItem">法律顾问</div>
            </div>
        </div>
    );
}

export default Business;