import axios from 'axios'

// const host = 'http://localhost:9999/';
const host = 'http://www.fuxianyulawyer.com/yu/';

let fns = {
    async ListArticle(params) {
        let url = host + 'article/listArticle';
        let result = await axios.post(url, params);

        let dataStatus = "T";
        if (undefined === result.data || null === result.data) {
            dataStatus = "F";
        }
        else {
            if (result.data.code !== "0") {
                dataStatus = "F";
            }
        }
        return {
            data: result.data,
            dataStatus: dataStatus
        };
    },

    async GetArticle(params) {
        console.log(params);
        let url = host + 'article/getArticle';
        let result = await axios.post(url, params);
        return result.data;
    },

    async AddMessage(params) {
        let url = host + 'message/addMessage';
        var result = {
            message: "留言成功",
            status: "0"
        };
        await axios.post(url, params)
            .then(function (res) {
                console.log(res);
                if (res.status !== 200) {
                    result.message = "留言失败！";
                    result.status = "1";
                }
                else {
                    result.message = res.data.message;
                    result.status = res.data.code;
                }
            })
            .catch(function (error) {
                result.message = "留言失败！";
                result.status = "1";
            });
        return result;
    }

}

export default fns;