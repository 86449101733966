import React from 'react'

import '../assets/css/page-two.css'

import TopImage from '../component/top-image'
import Loadding from '../component/loadding'
import fns from '../store/asyncMethods'
import Error from '../component/error'
import Page from '../component/page'

class PageTwo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            dataStatus: "T",
            page: 1,
            pageSize: 10
        }
    }

    componentWillMount() {
        let state = this.props.location.state;
        console.log(state)
        if (undefined !== state && null !== state) {
            this.getDate(state.page, state.pageSize);
        }
        else {
            this.getDate(this.state.page, this.state.pageSize);
        }
    }

    render() {
        let element;
        if (this.state.dataStatus === "T") {
            if (null != this.state.data) {
                var result = this.state.data.results;
                var page = {
                    pages: result.pages,
                    current: result.current,
                    total: result.total,
                    size: result.size
                };
                element = result.records.map((item, index) => {
                    return (
                        <div key={item.id} className="content-item" onClick={() => this.getDetails(item.id)}>
                            <div className="article-title">
                                <div className="article-number">{index + 1}</div>{item.title}
                            </div>
                            <div className="describe">{item.describe}</div>
                            <div className="author">
                                <div className="author-name">作者：{item.author}</div>
                                <div className="article-time">时间：{new Date(parseInt(item.releaseDate)).toJSON().substr(0, 10)}</div>
                            </div>
                        </div>

                    );

                })
                element.push(<Page key={0} page={page} cutPage={this.cutPage} />)
            }
            else {
                element = <Loadding />
            }
        }
        else {
            element = <Error />
        }
        return (
            <div className="page-two">
                <div className="content">
                    <TopImage />
                    {element}
                </div>
                <div className="perch"></div>
            </div>
        );
    }

    getDetails = (id) => {
        this.props.history.replace(this.props.location.pathname, {
            page: this.state.page,
            pageSize: this.state.pageSize
        });

        this.props.history.push({
            pathname: `/details`,
            state: {
                artilceId: id,
                detailsName: "2"
            }
        });
    }

    cutPage = (page, pageSize) => {
        this.getDate(page, pageSize);
    }

    getDate = async (page, pageSize) => {
        let result = await fns.ListArticle({
            page: page,
            pageSize: pageSize,
            releaseType: "R",
            type: "P"
        });
        this.setState({
            data: result.data,
            dataStatus: result.dataStatus,
            page: page,
            pageSize: pageSize
        })
    }

}

export default PageTwo;