import React from 'react'

import Synopsis from '../component/synopsis'
import Business from '../component/business'

import '../assets/css/page-one.css'
import TopImage from '../component/top-image'
import SelfTop from '../component/self-top'

class PageOne extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="page-one">
                <div className="main">
                    <TopImage />
                    <div className="content">
                        <SelfTop />
                    </div>
                    <Synopsis></Synopsis>
                    <Business></Business>
                    <div className="perch"></div>
                </div>
            </div>
        )
    };
}

export default PageOne;