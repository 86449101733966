function Synopsis() {
    return (
        <div className='synopsis'>
            <div className="title">
                <div className="title-line"></div>
                <div className="title-txt">律师简介</div>
            </div>
            <div className='info'>付显育律师，法学学士，毕业于昆明理工大学津桥学院，毕业后就职于云南典卫律师事务所，拥有专业的法律知识和丰富的工作经验，了解各类法律及相关文本的书写；具有较强的表达和沟通能力；具有较强的判断能力及逻辑分析能力；其主要执业方向是关于民事案件和法律顾问方面的工作。</div>
        </div>
    );
}

export default Synopsis;