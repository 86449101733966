import React from 'react'

import fns from '../store/asyncMethods'
import Loadding from '../component/loadding'

import '../assets/css/details.css'

class Details extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }

    async componentWillMount() {
        let result = await fns.GetArticle({ id: this.props.location.state.artilceId });
        this.setState({
            data: result
        });
    }

    render() {
        console.log(this.props)
        if (null != this.state.data) {
            let articleInfo = this.state.data.results
            return (
                <div className="details">
                    <div className="top">
                        <div className="return-icon" onClick={() => this.goBack()}></div>
                        <div className="details-name">{articleInfo.type === "P" ? "文集详情" : "案例详情"}</div>
                    </div>
                    <div className="article">
                        <div className="article-title">{articleInfo.title}</div>
                        <div className="other">
                            <div className="author">{articleInfo.type === "P" ? "作者: " : "案由: "}{articleInfo.author}</div>
                            <div className="time">时间: {new Date(parseInt(articleInfo.releaseDate)).toJSON().substr(0, 10)}</div>
                        </div>
                        <div className="article-content">{articleInfo.content}</div>
                        <div className="perch"></div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <Loadding />
            );
        }
    }

    goBack = () => {
        this.props.history.goBack(-1);
    }

}

export default Details;