import React from 'react';

import '../assets/css/page.css'

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current: 1
        }
    }

    render() {
        var page = this.props.page;
        return (
            <div className="page-com">
                {
                    page.current === 1 ? <div className="previous disenable">上一页</div>
                        : <div className="previous" onClick={() => this.previous(page)}>上一页</div>
                }
                <div className="current">{page.current}</div>
                {
                    page.current >= page.pages ? <div className="next disenable">下一页</div>
                        : <div className="next" onClick={() => { this.nextPage(page) }}>下一页</div>
                }
            </div>
        );
    };

    previous = (page) => {
        var page = this.props.page;
        if (page.current === 1) {
            return;
        }
        this.props.cutPage(page.current - 1, page.size);
    }

    nextPage = (page) => {
        var page = this.props.page;
        if (page.current >= page.pages) {
            return;
        }
        this.props.cutPage(page.current + 1, page.size);
    }

}

export default Page;