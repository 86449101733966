import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Link, Route } from 'react-router-dom';

import './assets/css/index.css';
import './js/reactive';

import PageOne from './view/page-one'
import PageTwo from './view/page-two';
import PageThree from './view/page-three';
import PageFour from './view/page-four';
import Details from './view/details';
import Bottom from './component/bottom';
import Message from './view/message'

class App extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Router>
                <Route path="/" exact component={PageOne} />
                <Route path="/article" exact component={PageTwo} />
                <Route path="/case" exact component={PageThree} />
                <Route path="/self" exact component={PageFour} />
                <Route path="/details" exact component={Details} />
                <Route path="/message" exact component={Message} />
                <div className="broadside">
                    <a className="tell-me" href="tel:18468053510"></a>
                    <Link to="/message" className="message-me" history={this.props.history}></Link>
                </div>
                <Bottom />
            </Router>
        );
    }

}

ReactDOM.render(
    <App />,
    document.querySelector("#root")
)