import '../assets/css/self-top.css'

function SelfTop() {

    return (
        <div className="self-top">
            <div className="head"></div>
            <div className="right">
                <div className="name">付显育</div>
                <div className="post">执业律师</div>
                <div className="org">云南典卫律师事务所</div>
            </div>
        </div>
    );
}

export default SelfTop;