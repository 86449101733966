(function() {
	function reactive() {
		/* var html = document.querySelector("html");
		var userAgent = navigator.userAgent;
		html.className = "";
		if (userAgent.indexOf("iPhone") !== -1) {
			html.classList.add("iphone");
		}
		else if (userAgent.indexOf("Android") !== -1) {
			html.classList.add("android");
		}
		else if (userAgent.indexOf("iPad") !== -1) {
			html.classList.add("ipad");
		}
		else{
			html.classList.add("pc");
		}
		
		if(window.innerWidth < 640) {
			html.classList.add("lt640");
			html.classList.add("lt960");
			html.classList.add("lt1200");
		}
		else if(window.innerWidth < 960) {
			html.classList.add("gt640");
			html.classList.add("lt960");
			html.classList.add("lt1200");
		}
		else if(window.innerWidth < 1200) {
			html.classList.add("gt640");
			html.classList.add("gt960");
			html.classList.add("lt1200");
		}
		else{
			html.classList.add("gt640");
			html.classList.add("gt960");
			html.classList.add("gt1200");
		} */
		
		var screenWidth = window.innerWidth;
		//屏幕的宽度/设计稿沾满全屏的宽度为多少
		var unit = screenWidth / 3.75;
		var html = document.querySelector("html");
		html.style.fontSize = unit + "px";
		
	}
	
	 reactive();
	 
	 //屏幕发生改变时刷新
	 window.onresize = function() {
		  reactive();
	 }
	 
})();