import React from 'react'

import '../assets/css/message.css'

import TopImage from '../component/top-image'
import fns from '../store/asyncMethods'

import { Toast, Button } from 'antd-mobile';

class Message extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            phone: "",
            address: "",
            question: ""
        }
    }

    render() {
        return (
            <div className="message">
                <TopImage />
                <div className="content">
                    {/* <div className="title">
                        <div className="title-txt">给我留言：</div>
                    </div> */}
                    <div className="messageItem">
                        <div className="itemTitle">姓名:</div>
                        <input type="text" onChange={(e) => this.handleChange(e, "userName")} placeholder="请输入您的姓名" />
                    </div>
                    <div className="messageItem">
                        <div className="itemTitle">电话:</div>
                        <input type="text" onChange={(e) => this.handleChange(e, "phone")} placeholder="请输入您的联系方式" />
                    </div>
                    <div className="messageItem">
                        <div className="itemTitle">地址:</div>
                        <input type="text" onChange={(e) => this.handleChange(e, "address")} placeholder="请输入您的地址" />
                    </div>
                    <div className="messageItem">
                        <div className="itemTitle">问题描述:</div>
                        <textarea rows="" cols="" onChange={(e) => this.handleChange(e, "question")} placeholder="请输入您的问题"></textarea>
                    </div>
                    <div className="messageItem">
                        {/* <Button disenable={this.state.btnLoading} type="primary" style={{ marginTop: "0.4rem", backgroundColor: "#edb96d", borderColor: "#edb96d"}} onClick={(e) => this.submitMessage(e)}>提交</Button> */}
                        <input className="submit-btn" type="button" onClick={(e) => this.submitMessage(e)} value="提交" />
                    </div>
                </div>
            </div>
        );
    }

    handleChange = (event, fileName) => {
        const valuse = event.target.value
        if ("userName" === fileName) {
            this.setState({
                name: valuse
            })
        }
        else if ("phone" === fileName) {
            this.setState({
                phone: valuse
            })
        }
        else if ("address" === fileName) {
            this.setState({
                address: valuse
            })
        }
        else if ("question" === fileName) {
            this.setState({
                question: valuse
            })
        }
    }

    submitMessage = async (e) => {
        e.target.disabled = true;
        var history = this.props.history;
        const params = {
            name: this.state.name,
            mobile: this.state.phone,
            address: this.state.address,
            question: this.state.question
        }
        var result = await fns.AddMessage(params);
        if ("1" === result.status) {
            e.target.disabled = false;
            Toast.fail(result.message, 1.2);
        }
        else {
            Toast.success(result.message, 1.2);
            setTimeout(function () {
                history.goBack(-1);
            }, 1000);
        }
    }

}

export default Message;