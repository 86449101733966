import React from 'react';

import { Link } from 'react-router-dom';

import Tab01 from '../assets/image/tab/tab-01.png';
import Tab02 from '../assets/image/tab/tab-02.png';
import Tab03 from '../assets/image/tab/tab-03.png';
import Tab04 from '../assets/image/tab/tab-04.png';

import TabAct01 from '../assets/image/tab/tab-active-01.png';
import TabAct02 from '../assets/image/tab/tab-active-02.png';
import TabAct03 from '../assets/image/tab/tab-active-03.png';
import TabAct04 from '../assets/image/tab/tab-active-04.png';

import '../assets/css/bottom.css'

class Bottom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageIndex: "/"
        }
    }

    componentWillMount() {
        var pathName = window.location.pathname;
        this.setState({
            pageIndex: pathName
        })
    }

    render() {
        return (
            <div >
                <div className="bottom" onClick={this.catPage}>
                    <Link to="/" replace>
                        <div className="item" data-index="/">
                            <img src={this.state.pageIndex === "/" ? TabAct01 : Tab01} alt="" className="icon"></img>
                            <div className="txt">首页</div>
                        </div>
                    </Link>

                    <Link to="/article" replace>
                        <div className="item" data-index="/article">
                            <img src={this.state.pageIndex === "/article" ? TabAct02 : Tab02} alt="" className="icon"></img>
                            <div className="txt">专业文集</div>
                        </div>
                    </Link>

                    <Link to="/case" replace>
                        <div className="item" data-index="/case">
                            <img src={this.state.pageIndex === "/case" ? TabAct03 : Tab03} alt="" className="icon"></img>
                            <div className="txt">我的案例</div>
                        </div>
                    </Link>

                    <Link to="/self" replace>
                        <div className="item" data-index="/self">
                            <img src={this.state.pageIndex === "/self" ? TabAct04 : Tab04} alt="" className="icon"></img>
                            <div className="txt">我的名片</div>
                        </div>
                    </Link>
                </div>
                <div className="beian">
                    <a>备案信息：</a>
                    <a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2022000895号-1</a>
                </div>
            </div>

        )
    }

    catPage = (e) => {
        var clickClass = e.target.className;
        var index = "1";
        if ("icon" === clickClass || "txt" === clickClass) {
            index = e.target.parentElement.dataset.index;
        }
        else {
            index = e.target.dataset.index;
        }
        if (undefined !== index) {
            this.setState({
                pageIndex: index
            });
        }
    }
}

export default Bottom
